.templates {
  padding: 20px;
}

.templates h1 {
  font-size: 2rem;
  font-weight: 500;
  padding: 0 20px 10px;
}

.templates h2 {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 30px 20px 10px;
}

.templates-main {
  flex-grow: 1;
}

.templates-list {
  display: flex;
  flex-wrap: wrap;
}

.template-group {
  width: 23%;
  height: 130px;
  display: flex;
  /* flex-direction: column; */
  margin: 10px !important;
  border-radius: 3px;
}

.soon {
  width: 18%;
}

.template-group img {
  width: 90px;
}

.from-gallery {
  height: 120px;
  width: 300px;
  margin: 0 20px 0 0;
  border-radius: 1px;
}
