#root {
  /* background-color: #e8e8e8; */
  background-color: #eaedee;
}

html {
  overflow: auto;
  background-color: #262a2e;
}

body {
  overflow: auto; /* Hide scrollbars */
}

.no-scroll {
  overflow: hidden !important; /* Hide scrollbars */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}

.no-scroll::-webkit-scrollbar {
  display: none;
}

.full-height {
  min-height: 100%;
}

.nav-footer {
  padding: 20px;
}

.blocks {
  display: flex;
}

.fh {
  height: 100%;
}

.im {
  position: absolute;
  bottom: 0;
  left: 25%;
}
