.modal-card.width-50 {
  width: 50%;
}

.modal-card.width-80 {
  width: 80%;
}

.modal-background {
  background-color: hsla(0, 0%, 0%, 0.5);
}

.modal-card {
  background-color: hsl(221, 14%, 100%);
  border-radius: 6px;
  min-height: 300px;
}

.modal-header {
  padding: 16px;
  display: flex;
  font-weight: bold;
}

.modal-card-body {
  padding: 0 20px 20px;
}
