#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

main {
  flex: 1;
  /* padding: 20px; */
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.site-layout {
  margin-top: 50px;
}
