.main-goal-wrap {
  background-image: url("/public/images/home-1.jpg");
  background-repeat: repeat;
}

.main-goal {
  /* background-color: #fff; */
  border-top: solid 1px #ccc;
  padding: 100px 70px 30px;
  background-image: url("/public/images/home.jpg");
  background-repeat: no-repeat;
  background-position: right top;
  min-height: 600px;
  color: #fff;
  /* display: flex;
  align-items: center; */
}

.main-goal h1 {
  font-size: 4vw;
  display: block;
  width: 50%;
  margin-bottom: 50px;
}

.functional .column {
  font-size: 1.3rem;
  /* background-color: #e7a728b1; */
  margin: 10px;
  border-radius: 10px;
  padding: 20px;
}

.main-examples {
  background-color: #fff;
  padding: 30px 70px;
}
.main-description {
  background-color: #f4cf87;
  padding: 30px 70px 50px;
}
.main-feedback {
  background-color: #fff;
  padding: 30px 70px;
}

.main-description h2 {
  text-align: center;
  font-size: 2rem;
  padding-bottom: 20px;
}

.description-step {
  display: flex;
  flex-direction: column;
  margin: 10px 50px;
}

.description-step p {
  padding: 0px;
}

.step-name {
  display: flex;
  font-size: 1.2rem;
  margin-bottom: 20px;
  align-items: center;
  font-weight: 500;
}

.step {
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  font-size: 2rem;
  margin-right: 10px;
}

@media screen and (max-width: 1023px) {
  .main-goal {
    background-image: unset;
    min-height: unset;
  }

  .main-goal h1 {
    font-size: 4vw;
    display: block;
    width: 100%;
    margin-bottom: 50px;
  }
}
