.main-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #323e48;
  padding: 0 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.logo {
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.4rem;
  color: #fff !important;
  margin-right: 30px;
}

.logo svg {
  margin-right: 10px;
  color: #8899a1;
}

.main-header .navbar-item {
  color: #a6b5bc;
  font-size: 1rem;
  font-weight: 500;
  /* padding: 2px 20px; */
}

.main-header .navbar-item:hover,
.main-header .navbar-item {
  background-color: unset !important;
}

.main-header .navbar-item:hover {
  color: #fff;
}

.navbar-brand,
.navbar-tabs {
  align-items: center;
}

.navbar-burger {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .navbar-menu {
    background-color: unset;
  }
}
