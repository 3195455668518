.tabs {
  width: 100%;
}

.tabs li {
  padding: 0 20px;
}

.tab-panel {
  width: 100%;
}
